import React from "react";

export const OutlineDot = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.49918"
      cy="6.06864"
      r="5.06864"
      stroke="#FFB000"
      strokeWidth="2"
      // fill="#ffffff"
    />
  </svg>
);

export const FilledDot = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="6.5" r="6.5" fill="#FFB000" />
  </svg>
);

export const MultipleDots = () => (
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="17" r="7" stroke="#FFB000" strokeWidth="2" />
    <circle cx="19" cy="4" r="3" stroke="#FFB000" strokeWidth="2" />
    <circle cx="24.5" cy="17.5" r="2.5" fill="#FFB000" />
    <circle cx="24.5" cy="17.5" r="2.5" stroke="#FFB000" />
  </svg>
);
